<script>
    import { required } from "vuelidate/lib/validators";
    import Auth from '@/services/Auth';
    import validationMessages from '@/components/validations'


    export default {
        components:{
            validationMessages
        },
        props: ['user_id'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                username: '',
                user: [],
                role_options: [],
                role: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                showLoader: false
            };
        },
        validations: {
            username: {
                required
            },
            role: {
                required
            },
        },
        methods: {

            async getRoles(){
                this.showLoader = true
                await Auth.getRoles().then((response) => {
                    this.role_options = response.data.data
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }).finally(() => {
                    this.showLoader = false
                })
            },

            async getUser(){
                this.showLoader = true
                await Auth.getUsers('id='+this.user_id)
                .then((response) => {
                    if(response.data.users){
                        this.user = response.data.users[0]
                        this.username = this.user.username
                        this.role = this.user.role_id
                    }
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }).finally(() => {
                    this.showLoader = false
                })
            },

            async EditUser(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()
                if(this.user_id == ''){
                    this.failedmsg("Missing user id");
                }

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Auth.editUser({
                            id: this.user_id,
                            username: this.username,
                            role: this.role,
                            role_name: this.getRoleNameById(this.role),
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            const res   = response.data.created  ? response.data.created  : false;
                            const error = response.data.error ? response.data.error : '';
                            if(res && error==''){
                                this.successmsg("User successfully changed");
                                this.closeModal();
                            }else{
                                this.failedmsg("Failed to change")
                            }

                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("User not saved")
                    }
                }
                this.tryingToEdit = false;
            },

            getRoleNameById(roleId) {
                const selectedRole = this.role_options.find((roleOption) => roleOption.id === roleId);
                return selectedRole ? selectedRole.name : '';
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
                this.username = ''
                this.role = ''
                this.role_name = ''
                this.tryingToSubmit = false
                this.submitted = false
            },

            initialData(){
                this.getRoles()
                this.getUser()
            }
        }
    };
</script>

<template>
    <b-modal @shown="initialData" v-model="showModal" id="edit_user" title="Edit User" title-class="font-18" size="lg" centered>
        <form @submit.prevent="EditUser" v-if="!showLoader">
            <div class="row">
                <div class="col">
                    <b-form-group label="Username" label-for="formrow-username-input" class="mb-3">
                        <b-form-input
                                v-model.trim="username"
                                type="text"
                                id="username"
                                aria-describedby="username-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.username.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Username'" :validationName="$v.username"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Role" label-for="formrow-role-input" class="mb-3">
                        <select v-model="role" class="form-select"
                            :class="{
                                      'is-invalid': submitted && $v.role.$error,
                                    }"
                        >
                            <option v-for="role in role_options" :key="role.id" :value="role.id">{{role.name}}</option>
                        </select>
                        <validationMessages v-if="submitted" :fieldName="'Role'" :validationName="$v.role"></validationMessages>
                    </b-form-group>

                </div>
            </div>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="EditUser" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>